import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'lumiron-card-header',
  templateUrl: 'card-header.component.html',
  styleUrl: './card-header.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class CardHeaderComponent {}
